/* src/components/LeadsTable.css */

.column-resizer {
    width: 5px;
    cursor: col-resize;
    padding: 4px;
    margin-left: 4px;
    height: 100%;
    display: inline-block;
  }
  
  