/* src/components/LeadsManagement.css */

/* General styles for fixed columns */
.fixed-column {
  /* position: sticky;  */
  background-color: #3C3C3C;
  color: #FFFFFF;
  z-index: 2;
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

/* Styles for the checkbox column */
.fixed-column.checkbox {
  /* position: sticky;  */
  left: 0;
  z-index: 3;
}

/* Styles for the lead details column */
.fixed-column.lead-details {
  left: 50px; /* Adjust based on your checkbox column width */
  z-index: 3;
}

/* Styles for the actions column */
.fixed-column.actions {
  /* position: sticky;  */
  right: 0;
  z-index: 3;
}

/* Header cells */
.MuiTableCell-head {
  /* position: sticky;  */
  /* top: 0;          */
  z-index: 3;
  background-color: #1F1F1F;
  color: #FFFFFF;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

/* Body cells */
.MuiTableCell-body {
  z-index: 1;
}

/* Additional styles */
.MuiTableHead-root {
  background-color: #1F1F1F;
}

.MuiTableBody-root {
  background-color: #3C3C3C;
}

.MuiTablePagination-root {
  background-color: #2E2E2E;
  color: #FFFFFF;
}

.MuiIconButton-root {
  color: #B4C0D3;
}

.MuiTableCell-root {
  color: #B4C0D3;
}
