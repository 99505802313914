/* CalendarStyles.css */

/* General Calendar Styles */
.rbc-calendar {
    background-color: #2E2E2E; /* theme.palette.background.default */
    color: #FFFFFF; /* theme.palette.text.primary */
  }
  
  .rbc-toolbar {
    background-color: #2E2E2E;
    color: #FFFFFF;
  }
  
  .rbc-toolbar button {
    color: #FFFFFF;
    background-color: transparent;
    border: none;
  }
  
  .rbc-toolbar button:hover {
    background-color: #3C3C3C; /* Slight hover effect */
  }
  
  .rbc-header {
    background-color: #3C3C3C; /* theme.palette.background.paper */
    color: #FFFFFF;
    border: none;
  }
  
  .rbc-header a {
    color: #FFFFFF;
  }
  
  .rbc-date-cell, .rbc-day-bg {
    background-color: #2E2E2E;
    color: #FFFFFF;
  }
  
  .rbc-today {
    background-color: #3C3C3C;
  }
  
  .rbc-event {
    background-color: #4876EE; /* theme.palette.primary.main */
    color: #FFFFFF;
  }
  
  .rbc-event-label, .rbc-event-content {
    color: #FFFFFF;
  }
  
  .rbc-time-content {
    border-top: 1px solid #3C3C3C;
  }
  
  .rbc-time-slot {
    border-top: 1px solid #3C3C3C;
  }
  
  .rbc-current-time-indicator {
    background-color: #FF5A79; /* theme.palette.secondary.main */
  }
  
  .rbc-off-range-bg {
    background-color: #1E1E1E;
  }
  
  .rbc-off-range {
    color: #B4C0D3; /* theme.palette.text.secondary */
  }
  
  .rbc-month-row {
    border-bottom: 1px solid #3C3C3C;
  }
  
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #3C3C3C;
  }
  
  .rbc-show-more {
    background-color: #2E2E2E;
    color: #FFFFFF;
  }
  
  .rbc-month-view {
    border: none;
  }
  
  .rbc-row {
    border: none;
  }
  